<template>
  <div v-if="shops">
    <CCard class="shadow-sm">
      <CCardBody>
        <CRow>
          <CCol sm="12" lg="12">
            <br />
            <div class="row">
              <h2 class="col-md-10 font-weight-normal text-dark">{{ $t('countMember') }}</h2>
              <div class="col-md-2 text-right text-success">
                <router-link to="/member/create">
                  <CButton color="primary" block class="text-white">
                    <em class="fas fa-user-plus"></em> {{ $t('addMenber') }}
                  </CButton>
                </router-link>
              </div>
            </div>
            <hr />
            <table style="width:100%" aria-describedby="">
              <th scope="col"></th>
              <tr>
                <td>
                  <input
                    class="form-control"
                    :placeholder="this.$i18n.t('searchMember')"
                    v-model="search"
                  />
                </td>
                <td style="width:15%;padding:5px">
                  <CButton
                    v-if="loadingButton === true"
                    v-on:click="getMemberReport()"
                    block
                    color="success"
                  >
                  {{ $t('search') }}
                  </CButton>

                  <CButton
                    block
                    color="success"
                    v-else-if="loadingButton === false"
                    disabled
                  >
                    <CSpinner color="white" size="sm" /> {{ $t('loading') }}
                  </CButton>
                </td>
              </tr>
            </table>

            <br />
            <CDataTable
              :items="items"
              :fields="fields"
              :loading="loading"
              clickableRows
              @row-clicked="toggleDetails"
              hover
              border
            >
              <template #name="{item}">
                <td class="py-2 text-dark">
                  {{ item.name }}
                  <img
                    alt=""
                    v-if="item.isMember === 'line'"
                    :src="imgSrc"
                    width="20px"
                  />
                </td>
              </template>
            </CDataTable>
          </CCol>
        </CRow>
        <pagination :meta_data="meta_data" v-on:next="getMemberReport">
        </pagination>
      </CCardBody>
    </CCard>
  </div>
  <div v-else>
    <no-shop></no-shop>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from '@/services/service'
import moment from 'moment'
import util from '@/util/util'
import Pagination from '@/containers/Pagination'
import NoShop from '@/containers/NoShop.vue'

export default {
  components: {
    Pagination,
    NoShop,
  },
  data() {
    return {
      data: [],
      search: '',
      loadingButton: true,
      loading: '',
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
      },
      imgSrc: '../../../img/line-icon.png',
    }
  },
  computed: {
    ...mapGetters(['shops', 'date', 'users']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    startDate() {
      return moment(String(this.date.start)).format('DD MMMM YYYY')
    },
    endDate() {
      return moment(String(this.date.end)).format('DD MMMM YYYY')
    },
    fields() {
      return [
        { key: 'order', label: '#' , _classes: "text-dark font-weight-normal"},
        { key: 'name', label: this.$i18n.t('customerName') , _classes: "text-dark font-weight-normal"},
        { key: 'dateOfBirth', label: this.$i18n.t('dateOfBirth') , _classes: "text-dark font-weight-normal"},
        { key: 'tel', label: this.$i18n.t('tel') , _classes: "text-dark font-weight-normal"},
        { key: 'email', label: this.$i18n.t('email'), _classes: "text-dark font-weight-normal"},
        { key: 'date', label: this.$i18n.t('dateOfMember'), _classes: "text-dark font-weight-normal"},
        { key: 'lastPurchase', label: this.$i18n.t('recentVisit'), _classes: "text-dark font-weight-normal"},
        { key: 'netTotal', label: this.$i18n.t('paymentTotal'), _classes: 'text-right text-dark font-weight-normal' },
        { key: 'point', label: this.$i18n.t('point'), _classes: 'text-right text-dark font-weight-normal' },
      ]
    },
    items() {
      const data = this.data || []
      let detail = []
      let start = this.meta_data.current_page * 50 - 50
      for (let i = 0; i < data.length; i++) {
        const item = data[i]
        const netTotal = util.convertCurrency(item.netTotal || 0)
        const point = util.convertNumber(item.point || 0)
        const isMember = item.isMember || ''

        // parse last purchased
        let lastPurchase = ''
        if (item.lastPurchase === undefined) {
          lastPurchase = '-'
        } else {
          lastPurchase = moment(String(item.lastPurchase)).format(
            'DD/MM/YYYY, HH:mm A'
          )
        }

        // parse date of birth
        let dateOfBirth = ''
        if (item.dateOfBirth == undefined ||
        item.dateOfBirth === '') {
          dateOfBirth = '-'
        } else {
          dateOfBirth = moment(item.dateOfBirth).format('DD/MM/YYYY')
        }

        // parse created at
        let createdAt = '-'
        if (item.created_at == undefined ||
          item.created_at === '') {
          createdAt = '-'
        } else if (moment(String(item.created_at)).isValid()) {
          createdAt = moment(String(item.created_at)).format('DD/MM/YYYY, HH:mm A')
        }
        const fullName = (item.firstname || item.name || '') + ' ' + (item.lastname || '')
        detail.push({
          objectId: item.objectId,
          order: start + i + 1,
          date: createdAt,
          dateOfBirth: dateOfBirth,
          lastPurchase: lastPurchase,
          name: fullName,
          tel: item.phone || 'N/A',
          email: item.email || 'N/A',
          address: (item.address || '') + ' ' + (item.postalCode || ''),
          netTotal: netTotal,
          point: point,
          isMember: isMember,
        })
      }
      return detail
    },
  },
  created() {
    this.getMemberReport()
  },
  methods: {
    ...util,
    getMemberReport(page = 1) {
      this.loadingButton = false
      this.loading = true
      const uid = this.uid

      const params = {
        search: this.search,
        page: page,
        limit: 50,
      }

      axios
        .get('/shop/v1.0/getcustomer/' + uid, { params })
        .then((res) => {
          this.data = res.data.data
          this.meta_data.last_page = res.data.paginate.pageCount
          this.meta_data.current_page = res.data.paginate.currentPage
          this.loading = false
          this.loadingButton = true
        })
        .catch((error) => {
          console.log(error)
        })
    },
    toggleDetails(item, index, event) {
      this.$router.push('/member/' + item.objectId + '/getdetail')
    },
  },
}
</script>
